<template>
  <div class="lab-items">
    <div class="lab-title-wrapper">
      <div class="lab-title">
        首页
      </div>
    </div>
    <div class="welcome-area index-panel">
      <div class="user-area">
        <div class="user-logo"></div>
        <div class="user-hello">你好，{{ user.realName }}！</div>
      </div>
      <button @click="jumpTo('/logout')" class="logout-btn">退出登录</button>

      <div class="user-mode" v-if="isAdmin">{{ modeName }}<div class="switch-mode" @click="toggleMode"><i class="switch-mode-icon"></i>模式切换</div></div>
      <div class="sys-name">欢迎登录{{this.$store.getters.envs.projectCnName}}</div>
    </div>

    <div class="op-holder index-panel">
      <div class="grid">

        <template v-if="curMode === 'user'">
          <div class="grid-cell-1-3">
            <div class="flex-item-holder" @click="jumpTo('/license')">
              <div class="lab-item">
                <i class="index-icon index-icon-booking"></i>
              </div>
              <div class="lab-item-txt">实验预约</div>
            </div>
          </div>

          <div class="grid-cell-1-3">
            <div class="flex-item-holder" @click="jumpTo('/bookings')">
              <div class="lab-item">
                <i class="index-icon index-icon-bookings"></i>
              </div>
              <div class="lab-item-txt">预约列表</div>
            </div>
          </div>

          <div class="grid-cell-1-3">
            <div class="flex-item-holder" @click="jumpTo('/license', {tabIndex:1})">
              <div class="lab-item">
                <i class="index-icon index-icon-license"></i>
              </div>
              <div class="lab-item-txt">实验许可</div>
            </div>
          </div>

          <div class="grid-cell-1-3">
            <div class="flex-item-holder" @click="jumpTo('/exp')">
              <div class="lab-item">
                <i class="index-icon index-icon-exps"></i>
              </div>
              <div class="lab-item-txt">实验记录</div>
            </div>
          </div>

          <div class="grid-cell-1-3">
            <div class="flex-item-holder" @click="jumpTo('/samples')">
              <div class="lab-item">
                <i class="index-icon index-icon-samples"></i>
              </div>
              <div class="lab-item-txt">送样记录</div>
            </div>
          </div>

          <div class="grid-cell-1-3">
            <div class="flex-item-holder" @click="jumpTo('/bill')">
              <div class="lab-item">
                <i class="index-icon index-icon-bill"></i>
              </div>
              <div class="lab-item-txt">账单详情</div>
            </div>
          </div>

          <template v-if="user.subjectAdmin">
            <div class="grid-cell-1-3">
              <div class="flex-item-holder" @click="jumpTo('/subject-mng')">
                <div class="lab-item">
                  <i class="index-icon index-icon-subject-mng"></i>
                </div>
                <div class="lab-item-txt">课题组成员</div>
              </div>
            </div>
          </template>

          <template v-if="user.companyAdmin">
            <div class="grid-cell-1-3">
              <div class="flex-item-holder" @click="jumpTo('/company-mng')">
                <div class="lab-item">
                  <i class="index-icon index-icon-company-mng"></i>
                </div>
                <div class="lab-item-txt">企业成员</div>
              </div>
            </div>
          </template>
        </template>

        <template v-if="curMode === 'admin'">

          <template v-if="isAdmin">
            <div class="grid-cell-1-3">
              <div class="flex-item-holder" @click="jumpTo('/license-mng')">
                <div class="lab-item">
                  <i class="index-icon index-icon-license-mng"></i>
                </div>
                <div class="lab-item-txt">实验许可管理</div>
              </div>
            </div>

            <div class="grid-cell-1-3">
              <div class="flex-item-holder" @click="jumpTo('/booking-mng')">
                <div class="lab-item">
                  <i class="index-icon index-icon-booking-mng"></i>
                </div>
                <div class="lab-item-txt">预约管理</div>
              </div>
            </div>

            <div class="grid-cell-1-3">
              <div class="flex-item-holder" @click="jumpTo('/exp-mng')">
                <div class="lab-item">
                  <i class="index-icon index-icon-exps"></i>
                </div>
                <div class="lab-item-txt">实验记录</div>
              </div>
            </div>

            <div class="grid-cell-1-3">
              <div class="flex-item-holder" @click="jumpTo('/finance-mng')">
                <div class="lab-item">
                  <i class="index-icon index-icon-finance-mng"></i>
                </div>
                <div class="lab-item-txt">财务账户</div>
              </div>
            </div>

            <div class="grid-cell-1-3">
              <div class="flex-item-holder" @click="jumpTo('/point-mng')">
                <div class="lab-item">
                  <i class="index-icon index-icon-point-mng"></i>
                </div>
                <div class="lab-item-txt">技能管理</div>
              </div>
            </div>
            <!--<div class="grid-cell-1-3">
              <div class="flex-item-holder">
                <div class="lab-item">
                  <i class="index-icon index-icon-states"></i>
                </div>
                <div class="lab-item-txt">传感器状态</div>
              </div>
            </div>-->

            <div class="grid-cell-1-3">
              <div class="flex-item-holder" @click="jumpTo('/authorities')">
                <div class="lab-item">
                  <i class="index-icon index-icon-authorities"></i>
                </div>
                <div class="lab-item-txt">门禁授权</div>
              </div>
            </div>

            <div class="grid-cell-1-3">
              <div class="flex-item-holder" @click="jumpTo('/monitor')">
                <div class="lab-item">
                  <i class="index-icon index-icon-monitor"></i>
                </div>
                <div class="lab-item-txt">实验室监控</div>
              </div>
            </div>
            <div class="grid-cell-1-3">
              <div class="flex-item-holder" @click="jumpTo('/events')">
                <div class="lab-item">
                  <i class="index-icon index-icon-events"></i>
                </div>
                <div class="lab-item-txt">传感器事件</div>
              </div>
            </div>
            <div class="grid-cell-1-3">
              <div class="flex-item-holder" @click="jumpTo('/eq-maintain')">
                <div class="lab-item">
                  <i class="index-icon index-icon-exps"></i>
                </div>
                <div class="lab-item-txt">设备维护</div>
              </div>
            </div>
            <div class="grid-cell-1-3">
              <div class="flex-item-holder" @click="jumpTo('/event-feedback')">
                <div class="lab-item">
                  <i class="index-icon index-icon-samples"></i>
                </div>
                <div class="lab-item-txt">事件响应</div>
              </div>
            </div>
            <div class="grid-cell-1-3">
              <div class="flex-item-holder" @click="jumpTo('/eq-violatrecord')">
                <div class="lab-item">
                  <i class="index-icon index-icon-bill"></i>
                </div>
                <div class="lab-item-txt">违规记录</div>
              </div>
            </div>
          </template>
        </template>
      </div>
    </div>
    <div class="scan-holder" v-if="curMode==='user'">
      <div class="scan" @click="jumpTo('/scan')"></div>
    </div>

  </div>
</template>
<style lang="scss" scoped>
.user-logo {
  background: url("../assets/images/user.png");
  background-size: cover;
  width: 66px;
  height: 66px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.18);
  border-radius: 33px;
}

.op-holder {
  background: #fff !important;
  margin-top: 20px;
  padding: 20px 0 0;
}

.lab-item {
  width: 44px;
  height: 44px;
  color: #fff;
  display: flex;
  align-items: center;
}

.lab-item-txt {
  padding-top: 5px;
  padding-bottom: 25px;
  font-size: 13px;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
}

.flex-item-holder {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.lab-items {
  //padding: 15px;
}

.lab-title-wrapper {
  height: 177px;
  background: url("../assets/images/index-banner.png") !important;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.lab-title {
  margin-top: 40px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}

.welcome-area {
  height: 133px;
  z-index: 99;
  margin-top: 25px;
  padding: 10px;
  position: relative;
}

.index-panel {
  border-radius: 10px;
  background: #FFFFFF;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
}

.flex-item-holder .index-icon {
  font-size: 28px;
  font-weight: 900;
  width: 100%;
  height: 100%;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.7);
  border: none;
  background-size: contain;
}

.index-icon-booking {
  background: url("../assets/images/booking.png");
}

.index-icon-bookings {
  background: url("../assets/images/bookings.png");
}

.index-icon-license {
  background: url("../assets/images/license.png");
}

.index-icon-events {
  background: url("../assets/images/events.png");
}

.index-icon-booking-mng {
  background: url("../assets/images/booking-mng.png");
}

.index-icon-authorities {
  background: url("../assets/images/authorities.png");
}

.index-icon-monitor {
  background: url("../assets/images/monitor.png");
}

.index-icon-bill {
  background: url("../assets/images/bill.png");
}

.index-icon-license-mng {
  background: url("../assets/images/license-mng.png");
}

.index-icon-subject-mng {
  background: url("../assets/images/subject-mng.png");
}

.index-icon-company-mng {
  background: url("../assets/images/company-mng.png");
}

.index-icon-samples {
  background: url("../assets/images/samples.png");
}

.index-icon-exps {
  background: url("../assets/images/exps.png");
}

.index-icon-finance-mng {
  background: url("../assets/images/finance.svg");
}

.index-icon-point-mng {
  background: url("../assets/images/point.svg");
}

.logout-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 77px;
  height: 30px;
  line-height: 30px;
  border-radius: 4px;
  border: 1px solid #FF4158;
  color: #FF4158;
  background: #fff;
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  top: 10px;
  right: 10px;
}

.user-area {
  position: relative;
  top: -42px;
}

.user-hello {
  font-size: 19px;
  font-weight: 400;
  color: #000000;
  margin-top: 14px;
}

.sys-name {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  text-align: center;
  position: relative;
  bottom: 18px;
  width: 100%;
}

.scan-holder {
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scan {
  width: 89px;
  height: 89px;
  background: url("../assets/images/scan.png");
  background-size: cover;
}

.user-mode {
  display: flex;
  align-items: center;
  justify-content: left;
  position: absolute;
  top: 10px;
  left: 80px;
  color: #3b5998;
  font-size: 12px;
}

.switch-mode {
  background-color: rgba(59, 89, 152, .1);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #3b5998;
  border-radius: 3px;
  margin-left: 10px;
  padding: 2px;
}

.switch-mode-icon {
  display: flex;
  width: 14px;
  height: 14px;
  background: url("../assets/images/switch.svg");
  background-size: cover;
  margin-right: 3px;
}
</style>
<script>
import sec from "@/plugins/user";
import {mapState} from "vuex";
import { Toast,Progress,MessageBox} from 'mint-ui';
import store from "@/plugins/store";

export default {
  components: {},
  data() {
    return {
      user: '',
      isAdmin: false,
      scheme:'',
      schemeFlag:false,
    }
  },
  mounted() {
    this.user = sec.getUser()
    this.isAdmin = sec.hasAnyRole('admin','eqAdmin', 'deptAdmin', 'allAdmin')
  },
  methods: {
    jumpTo(path, query) {
      this.$router.push({path: path, query: query || {}})
    },
    toggleMode(){
      const mode = this.$store.state.mode === 'user'? 'admin': 'user'
      this.$store.commit('setMode', mode)
    }
  },
  computed: mapState({
    // 为了能够使用 `this` 获取局部状态，必须使用常规函数
    modeName(state) {
      return state.mode === 'user'?'用户模式':'管理模式'
    },
    curMode(state) {
      return state.mode
    }
  }),
}
</script>
